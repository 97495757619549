import * as React from "react"
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Heading } from "@global";
import { Kontainer } from "@util/standard";
import { PRIMARY_COLOR } from "@util/constants"

const Section404 = styled.section`
  background-color:${PRIMARY_COLOR};
  height:100vh;
  display:flex;
  align-items:center;
  text-align:center;
  h1{
    width:100%;
  }
`

// markup
const NotFoundPage = () => {
  return (
    <Section404>
      <Helmet>
        <title>404 Page not Found</title>
      </Helmet>
        <Kontainer size="wide">
            <Heading data={{design: 'h1', tag: 'h1' }} inverse={true} >404 PAGE NOT FOUND</Heading>
        </Kontainer>
    </Section404>
  )
}

export default NotFoundPage
